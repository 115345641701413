<template>
    <div class="formBody">
        <v-row align="center" style="width: 100vw">
            <v-row justify="space-around" style="margin: 0 0 0 0">
                <div>
                    <v-dialog
                            v-model="dialog"
                            width="500"
                    >
                        <v-card>
                            <v-card-title
                                    class="headline primary"
                                    primary-title
                            >
                                {{this.$t('dialog.dataFound.headline')}}
                            </v-card-title>

                            <v-card-text class="mt-3">
                                {{this.$t('dialog.dataFound.body')}}
                            </v-card-text>

                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                        @click="clearRecoverData()"
                                        color="error"
                                        text
                                >
                                    {{this.$t('button.delete')}}
                                </v-btn>
                                <v-btn
                                        @click="recoverData()"
                                        color="primary"
                                >
                                    {{this.$t('button.restore')}}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <h1>ARAG</h1>
                  <v-progress-linear indeterminate color="primary" v-if="isLoading"></v-progress-linear>

                  <v-form
                            :lazy-validation="lazy"
                            class="form"
                            ref="form"
                            v-model="valid"
                    >
                        <v-container style="min-width: 25vw" v-on:input="save()">
                          <v-card color="transparent" max-width="800" flat >
                            <v-card-title>Prüfung Anstellungsvertrag</v-card-title>
                            <v-card-text :class="documentcheck ? 'mb-3' : 'pa-0'">
                              <v-row>
                                <v-col>
                                  <v-checkbox v-if="documentcheck && $RStore.user.permissions.insurance.aragDataType.PruefungAnstellungsvertrag"  v-model="processSelf" :label="inputLabels.processSelf"></v-checkbox>
                                </v-col>
                              </v-row>
                              <div v-if="documentcheck">
                                <v-row>
                                  <v-col cols="12">
                                    <v-text-field
                                        :label="inputLabels.name"
                                        :rules="nameRules"
                                        required
                                        v-model="name"
                                    ></v-text-field>

                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field
                                        v-model="email"
                                        :label="inputLabels.email"
                                        :rules="emailRules"
                                        required
                                    ></v-text-field>
                                  </v-col>

                                  <v-col>
                                    <v-text-field
                                        v-model="phone"
                                        :label="inputLabels.phone"
                                        :rules="phoneRules"
                                        required
                                    ></v-text-field>
                                  </v-col>
                                  <v-col>
                                    <v-checkbox
                                        v-model="sms"
                                        :label="inputLabels.sms"
                                        @change="save()"
                                        required
                                    ></v-checkbox>
                                  </v-col>
                                  <v-col cols="12">

                                    <v-text-field
                                        :label="inputLabels.policyNumber"
                                        :rules="policyNumberRules"
                                        type="number"
                                        :counter="maxCounterPolicyNumber"
                                        v-if="hideNumbers(claimNumber)"
                                        v-model="policyNumber"
                                    ></v-text-field>

                                    <v-text-field
                                        :label="inputLabels.claimNumber"
                                        :rules="claimNumberRules"
                                        type="number"
                                        v-if="hideNumbers(policyNumber)"
                                        v-model="claimNumber"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-textarea
                                        v-model="notes"
                                        :counter="300"
                                        :label="inputLabels.notes"
                                        :rules="notesRules"
                                        auto-grow
                                        required
                                    ></v-textarea>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-textarea
                                        :counter="500"
                                        :label="inputLabels.issue"
                                        :rules="issueRules"
                                        auto-grow
                                        required
                                        v-model="issue"
                                    ></v-textarea>
                                  </v-col>
                                  <v-col cols="12">

                                    <v-menu
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        min-width="290px"
                                        offset-y
                                        transition="scale-transition"
                                        v-model="consultationDateMenu"
                                    >
                                      <template v-slot:activator="{ on }">
                                        <v-text-field
                                            :label="inputLabels['consultationDate']"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            :value="formatDate(consultationDate)"
                                            v-on="on"
                                        ></v-text-field>
                                      </template>
                                      <v-date-picker @input="saveConsultationDate()" color="primary" show-current
                                                     v-model="consultationDate"></v-date-picker>
                                    </v-menu>
                                  </v-col>
                                  <v-col></v-col>
                                </v-row>
                              </div>
                            </v-card-text>

                          </v-card>





                        </v-container>

                        <v-row
                                class="mb-10"
                                justify="end"
                                md="4"
                        >
                            <v-btn
                                    @click="$router.back()"
                                    class="mr-4"
                                    color="error"
                            >
                                {{this.$t('button.cancel')}}
                            </v-btn>

                            <v-btn
                                    :disabled="blockButtonWhileRequestAndForm()"
                                    @click="validate"
                                    class="mr-4"
                                    color="success"
                            >
                                {{this.$t('button.sent')}}
                            </v-btn>
                        </v-row>
                    </v-form>
                </div>
            </v-row>
        </v-row>
    </div>
</template>

<script>
    import i18n from "../../../../plugins/i18n";
    import {getAragURL, requestMode} from "@/configBuilder";
    import {sessionHandler} from "@/request/sessionHandler";
    import {showAlert} from "@/utils/alertHandler";
    import dateFormatter from "../../../../utils/dateFormatter";
    import reactiveStorage from "@/plugins/reactiveStorage";

    export default {
        name: "Arag",
        data: () => ({
            valid: true,
            isLoading: false,
            documentcheck: true,
            processSelf: reactiveStorage.user.permissions.insurance.aragDataType.PruefungAnstellungsvertrag,
          phone: '+49',
          email: '',
          notes: '',
            policyNumber: '',
            claimNumber: '',
            checkNumbers: ["1100","1101","1000","1001"],
            name: '',
            activityType: {id: "01", name: "Straf/Owi Straßenverkehr"},
            issue: '',
            free: false,
          sms: true,
            date: new Date().toISOString().substr(0, 10),
            dateMenu: false,
            consultationDate: new Date().toISOString().substr(0, 10),
            consultationDateMenu: false,
            lazy: false,
            dialog: false,
          maxCounterPolicyNumber: 8,
            la: [
                {id: "01", name: "Straf/Owi Straßenverkehr"},
                {id: "02", name: "KfzSchadenersatzRS"},
                {id: "03", name: "KfzVertragSachRS"},
                {id: "04", name: "KfzVersVertragRS"},
                {id: "05", name: "KfzSteuerRS"},
                {id: "21", name: "VerwaltVerkehrsRS"},
                {id: "51", name: "Straf/Owi"},
                {id: "52", name: "SchadenersatzRS"},
                {id: "53", name: "Arbeits-RS"},
                {id: "54", name: "SozialRS"},
                {id: "55", name: "BeratungsRS"},
                {id: "56", name: "VertragsSachRS"},
                {id: "57", name: "Handelsvertreter RS"},
                {id: "58", name: "VersichsvertrRS"},
                {id: "59", name: "SteuerRS"},
                {id: "70", name: "VerwaltungsRS"},
                {id: "71", name: "FirmenvertragsRS"},
                {id: "98", name: "GrundstücksteuerRS"},
                {id: "99", name: "WEG"},
            ]
        }),

        methods: {
            // disable sent button so prevent multiple entries
            blockButtonWhileRequestAndForm(){
              if (this.valid === false) {return true}
              return this.isLoading === true;
            },

            // Form Data Request
            async sendData() {
                // set Loading true
                this.isLoading = true;

                // init Request Data
                const formData = {
                  "customerMailAddress": this.email,
                  "aragDataType": 'PruefungAnstellungsvertrag',
                  "notesDocument": this.notes,
                  "customerPhoneNumber": this.phone,
                  "claimNumber": this.claimNumber,
                  "comment": this.issue,
                  "consultationDate": this.consultationDate,
                  "contractNumber": this.policyNumber,
                  "customerName": this.name,
                };
                // console.log("data", formData)
                //  if Session isValid
                await sessionHandler();

                // send add to Database request
                await this.$http
                    .post(
                        getAragURL(false),
                        formData
                        , {
                            mode: requestMode(),
                            params: {
                              "pruefunganstellungsvertragProcessSelf": this.processSelf,
                              "sendSMS": this.sms,
                            },
                            headers: {
                                'Accept': 'application/json',
                                'authorization': this.$RStore.app.AuthenticationType + ' ' + this.$cookies.get('access_token'),
                            },
                        }
                    )
                    .then((response) => {
                        if (response.status === 200) {
                            this.clearRecoverData();
                            showAlert(i18n.t('success.form'), "success");
                            this.$router.push("/")
                        } else {
                            showAlert(i18n.t('error.api.undefined') + "ARAG-sD1", "error");
                        }
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                showAlert(i18n.t('warning.unauthorised'), "warning");
                            } else {
                                showAlert(i18n.t('error.api.undefined') + "AdvocardPrivate-sD2", "error");
                            }
                        } else {
                            showAlert(i18n.t('error.api.undefined') + "ARAG-sD2", "error");
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    })
            },

            // send button pressed
            validate() {
                if (this.$refs.form.validate()) {
                    this.sendData();
                }

            },

            // Save to localStorage
            save() {
                let store = {
                    "policyNumber": this.policyNumber,
                    "claimNumber": this.claimNumber,
                    "processSelf": this.processSelf,
                  "documentcheck": this.documentcheck,
                  "notesDocument": this.notes,
                  "customerPhoneNumber": this.phone,
                  "email": this.email,
                  "sms": this.sms,
                    "name": this.name,
                    "activityType": this.activityType,
                    "date": this.date,
                    "issue": this.issue,
                    "free": this.free,
                    "consultationDate": this.consultationDate
                }
                localStorage.setItem('aragPrue', JSON.stringify(store));

            },
            saveDate() {
                this.save();
                this.dateMenu = false
            },
            saveConsultationDate() {
                this.save();
                this.consultationDateMenu = false;
            },

            // Clear localStorage
            clearRecoverData() {
                localStorage.setItem('aragPrue', "")
                this.dialog = false;
            },

            // Set localStorage to Formfields
            recoverData() {
                let recoverableData = localStorage.getItem('aragPrue');
                recoverableData = JSON.parse(recoverableData);
                this.policyNumber = recoverableData.policyNumber;
              this.processSelf = recoverableData.processSelf;
              this.documentcheck = recoverableData.documentcheck;
                this.claimNumber = recoverableData.claimNumber;
                this.name = recoverableData.name;
              this.email = recoverableData.email;
              this.phone = recoverableData.customerPhoneNumber;
              this.sms = recoverableData.sms;
              this.notes = recoverableData.notesDocument;
                this.activityType = recoverableData.activityType;
                this.date = recoverableData.date;
                this.issue = recoverableData.issue;
                this.free = recoverableData.free;
                this.consultationDate = recoverableData.consultationDate;
                this.dialog = false;
            },

            //  if there is data to recover
            checkRecoverData() {
                let recoverableData = localStorage.getItem('aragPrue');
                if (recoverableData) {
                    this.dialog = true;
                }
            },

          //Hide policyNumber/claimNumber
          hideNumbers(otherNumber) {
            if (this.free) {
              return false;
            }
            if (otherNumber === '') {
              return true;
            }
          },

            // format Date
            formatDate(unformedDate){
                return dateFormatter(unformedDate);
            },
        },
        computed: {
            errorMessage() {
                return JSON.parse(JSON.stringify(this.$t('error.validation')));
            },
            inputLabels() {
                return JSON.parse(JSON.stringify(this.$t('insurance.Arag.inputField')));
            },
          emailRules() {
            return [
              v => /^\S+@\S+\.\S+$/.test(v) || this.errorMessage["validEmail"],
              v => !!v || this.errorMessage.required,
            ]
          },
          notesRules() {
            return [
              v => !!v || this.errorMessage.required,
              v => (v && v.length <= 300) || this.errorMessage["maxLength300"],
            ]
          },
          phoneRules() {
            return [
              v => /\+[0-9]{2}[1-9][0-9]{1,25}/.test(v) || this.errorMessage["validPhone"],
              v => !!v || this.errorMessage.required,
            ]
          },
            policyNumberRules() {
                return [
                    v => v.length > 0 ? /^\d+$/.test(v) || this.errorMessage["onlyNumbers"] : true,
                    v => v !== "00000000"
                ]
            },
            claimNumberRules() {
                return [
                  v => /^\d+$/.test(v) || this.errorMessage["onlyNumbers"],
                  v => (v.substring(0,1).includes("3") && v.length === 14)|| this.errorMessage["invalidClaimNumber"],
                  v => !!v || this.errorMessage.required,
                ]
            },
            nameRules() {
                return [
                    v => !!v || this.errorMessage.required,
                ]
            },
            activityTypeRules() {
                return [
                    v => !!v || this.errorMessage.required,
                ]
            },
            issueRules() {
                return [
                    v => (v && v.length <= 500) || this.errorMessage["maxLength500"],
                    v => !!v || this.errorMessage.required,
                ]
            }
        },
        mounted() {
            this.checkRecoverData();
        },
        watch: {
          policyNumber: {
            handler() {
              this.maxCounterPolicyNumber = (-1 !== this.checkNumbers.findIndex(element => {
                if(this.policyNumber.length > 3){
                  if (element.includes(this.policyNumber.substring(0,4))) {
                    return true;
                  }
                }
              }) ? 14 : 8);
            }
          }
        }
    }
</script>

<style lang="stylus" scoped>
    .formBody
        margin-top 3vh

    .form
        margin-top 1vh

</style>
<!-- Hide Number Button -->
<style>

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
